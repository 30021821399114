import React from 'react';
import propTypes from 'prop-types';

import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import EditNoteIcon from '@mui/icons-material/EditNote';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

import RESPONSES from './responses';

export default function ResponseIcon({ response }) {
  switch (response) {
    case RESPONSES.PENDING: return <AccessTimeIcon className="response-icon pending" />;
    case RESPONSES.REJECTED: return <ClearIcon className="response-icon rejected" />;
    case RESPONSES.ACCEPTED: return <CheckIcon className="response-icon accepted" />;
    case RESPONSES.WITH_REVISIONS: return <EditNoteIcon className="response-icon accepted" />;
    default: return <QuestionMarkIcon className="response-icon" />;
  }
}
ResponseIcon.propTypes = { response: propTypes.oneOf(Object.values(RESPONSES)).isRequired };
