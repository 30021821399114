import React from 'react';
import propTypes from 'prop-types';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DangerousIcon from '@mui/icons-material/Dangerous';
import LightbulbIcon from '@mui/icons-material/Lightbulb';

import './InlineMessage.css';
import Text from '../Text';

const MESSAGE_TYPES = [
  'error',
  'warning',
  'success',
  'hint',
];

function Icon({ type = undefined }) {
  switch (type) {
    case 'error': return <ErrorOutlineIcon />;
    case 'warning': return <DangerousIcon />;
    case 'success': return <CheckCircleOutlineIcon />;
    case 'hint': return <LightbulbIcon />;
    default: return null;
  }
}
Icon.propTypes = {
  type: propTypes.oneOf(MESSAGE_TYPES),
};

function Content({ type = undefined, text }) {
  return (
    <>
      <Icon type={type} />
      <Text variant="subtitle1">
        {text}
      </Text>
    </>
  );
}
Content.propTypes = {
  type: propTypes.oneOf(MESSAGE_TYPES),
  text: propTypes.string.isRequired,
};

function InlineMessage({
  text = 'Something went wrong.',
  type = undefined,
  arrow = undefined,
  onClick = undefined,
}) {
  const classes = [
    'inline-message',
  ];
  if (type) { classes.push(type); }
  if (arrow) { classes.push('arrow'); classes.push(arrow); }

  if (onClick) {
    return (
      <button
        type="button"
        className={classes.join(' ')}
        onClick={onClick}
      >
        <Content type={type} text={text} />
      </button>
    );
  }

  return (
    <div className={classes.join(' ')}>
      <Content type={type} text={text} />
    </div>
  );
}

export default InlineMessage;

InlineMessage.propTypes = {
  text: propTypes.string,
  type: propTypes.oneOf(MESSAGE_TYPES),
  arrow: propTypes.oneOf([
    undefined,
    'top',
    'right',
    'bottom',
    'left',
  ]),
  onClick: propTypes.func,
};
