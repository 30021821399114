import NONE from '../../../constants';
import formatQuestions from './formatQuestions';
import requiredValuesMissing from './requiredValuesMissing';
import queryStringToObject from './queryStringToObject';
import queryStringToParams from './queryStringToParams';
import questionsToQuery, { questionsToQueryString } from './questionsToQuery';
import deepCopy from './deepCopy';

/* eslint camelcase: off */

function equivalent(obj1, obj2) {
  const str1 = JSON.stringify(obj1);
  const str2 = JSON.stringify(obj2);
  return str1 === str2;
}

function sameElements(arr1, arr2) {
  if (!Array.isArray(arr1)) throw new Error('sameElements expected arr1 to be an array');
  if (!Array.isArray(arr2)) throw new Error('sameElements expected arr2 to be an array');
  if (arr1.length !== arr2.length) return false;
  for (let i = 0; i < arr1.length; i += 1) {
    if (!arr2.includes(arr1[i])) return false;
  }
  return true;
}

function indexToLetter(idx) {
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  if (idx < alphabet.length) { return alphabet[idx]; }
  return alphabet[Math.floor(idx / 26) - 1] + alphabet[idx % 26];
}

const queryStringToPath = (query) => {
  const params = queryStringToObject(query);
  if (!params.path_keys) return '';
  const pathKeys = JSON.parse(params.path_keys);
  if (!Array.isArray(pathKeys)) return '';
  return pathKeys.map((key) => params[key]).join('/');
};

const oldDataSetToNew = (dataSet) => {
  const columns = dataSet.cols.map(({
    name,
    expression,
    footnotes,
  }) => ({
    id: name
      .replace(/ /g, '_')
      .replace(/:/g, '_'),
    name,
    expression,
    description: footnotes,
  }));
  const columnKeys = columns.map(({ id }) => id);
  const rows = dataSet.data.map((rowArray) => {
    const rowObject = {};
    columnKeys.forEach((key, index) => {
      rowObject[key] = rowArray[index];
    });
    return rowObject;
  });
  return { columns, rows };
};

const objMissingRequiredField = (obj) => {
  const keys = Object.keys(obj);
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    const question = obj[key];
    if (!question.optional && !question.value) {
      return true;
    }
    if (!question.optional && question.value === NONE) {
      return true;
    }
    if (!question.optional
      && Array.isArray(question.value)
      && !question.value.length) {
      return true;
    }
  }
  return false;
};

/*
 * options should be an array of objects.
 * e.g. options = [
 *   { id: REALE, name: 'Housing and Other Real Estate' },
 *   { id: AGRIC, name: 'Agriculture' },
 * ];
 * but may be given as an object
 * e.g. options = {
 *   REALE: 'Housing and Other Real Estate',
 *   AGRIC: 'Agriculture',
 * }
 * or an object of objects, where each child object is the options
 * dependant on some parent value.
 * e.g. options = {
 *   ENVI: {
 *     AGRIC: 'Agriculture',
 *   },
 *   ECON: {
 *     REALE: 'Housing and Other Real Estate',
 *   },
 * }
 * where if the parent is set to ['ENVI'], only the option 'AGRIC' should be returned,
 * if the parent is set to ['ECON'], only the option 'REALE' should be returned,
 * and if parent is set to ['ECON', 'ENVI'], both should be returned.
 * We will assume, for the purposes of this function, that all options should be returned.
 * Prune your options object to match the parent's value before passing it in.
 */
function optionsToArray(options) {
  if (Array.isArray(options)) return options;
  let joinedOptions = [];
  if (!options) return joinedOptions;
  Object.keys(options).forEach((optionKey) => {
    const optionValue = options[optionKey];
    switch (typeof optionValue) {
      case 'object':
        joinedOptions = joinedOptions.concat(optionsToArray(optionValue));
        return;
      default:
        joinedOptions.push({ id: optionKey, name: String(optionValue) });
    }
  });
  return joinedOptions;
}

const getMultipleSelectDefaultValue = ({ choices, default: defaultValue }) => {
  if (String(defaultValue).toLowerCase() === 'none') return [];
  return optionsToArray(choices).map(({ id }) => id);
};

export function resultsToArray(results, headers) {
  if (Array.isArray(results)) return results;
  const resultsKeys = Object.keys(results);

  const findPrimaryKey = () => Object.keys(headers)
    .find((key) => headers[key].isKeyField);
  const guessPrimaryKey = () => '_primaryKey';

  const primaryKey = findPrimaryKey() || guessPrimaryKey();
  return resultsKeys.map((key) => {
    const row = { ...results[key] };
    if (!row[primaryKey]) { row[primaryKey] = key; }
    return row;
  });
}

export function headersToColumns(headers) {
  const keys = Object.keys(headers);
  const columns = keys
    .filter((key) => !headers[key].hidden)
    .map((key) => ({
      ...headers[key],
      id: key,
      linkActivate: headers[key].linkActivate || headers[key]['link-activate'],
      name: headers[key].name || headers[key].display_name,
      type: headers[key].type || headers[key]['field-type'],
    }));
  return [...columns, { id: '_primaryKey', hidden: true, isKeyField: true }];
}

export function questionsToPostParams(questions) {
  const postParams = {};
  const keys = Object.keys(questions);
  keys.forEach((key) => { postParams[key] = questions[key].value; });
  return postParams;
}

export function getErrorMessage(error) {
  const message = 'Something went wrong.';
  try {
    if (typeof error === 'string') return error;
    if (typeof error.message === 'string') return error.message;
    return error.response.data.message;
  } catch {
    return message;
  }
}

export function getProperty(object, index = 0) {
  try {
    return object[Object.keys(object)[index]];
  } catch {
    throw new Error(`getProperty: given object has fewer than ${index + 1} keys.`);
  }
}

export {
  deepCopy,
  indexToLetter,
  equivalent,
  formatQuestions,
  sameElements,
  questionsToQuery,
  questionsToQueryString,
  queryStringToObject,
  queryStringToParams,
  queryStringToPath,
  oldDataSetToNew,
  objMissingRequiredField,
  getMultipleSelectDefaultValue,
  optionsToArray,
  requiredValuesMissing,
};
