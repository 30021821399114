import React from 'react';
import propTypes from 'prop-types';

import HistoryIcon from '@mui/icons-material/History';
import { Draggable } from 'react-beautiful-dnd';

import { MANUSCRIPT_PROPS } from './dashboardProps';
import daysBetween from './daysBetween';
import AssigneeAvatar from './AssigneeAvatar';
import manuscriptOverdue from './manuscriptOverdue';
import stringifyAuthorNames from './stringifyAuthorNames';

function daysBetweenToColor(days) {
  if (days < 30) return 'green';
  if (days < 60) return 'orange';
  return 'red';
}

export default function Manuscript({
  manuscript,
  onClick,
  zoomLevel,
  index,
}) {
  const {
    _id: id,
    title,
    authors,
    timeUpdated,
    reviewers,
  } = manuscript;
  const authorNames = stringifyAuthorNames(authors);
  const daysSinceUpdate = daysBetween(new Date(), new Date(timeUpdated));
  const buttonClasses = ['dashboard-manuscript', 'white-background'];
  if (manuscriptOverdue(manuscript)) buttonClasses.push('overdue');
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <li>
      <Draggable draggableId={id} index={index}>
        {(provided, snapshot) => (
          <button
            type="button"
            className={buttonClasses.join(' ')}
            onClick={onClick}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={snapshot ? provided.draggableProps.style : provided.draggableProps.style}
          >
            <div className="heading">
              <div className="title-and-authors">
                <h3>{title}</h3>
                <div>{authorNames}</div>
              </div>
            </div>
            <div className="footer">
              <div className="assignees">
                {reviewers && reviewers.map(({ name, response }) => (
                  <AssigneeAvatar key={name} name={name} response={response} />
                ))}
              </div>
              { !Number.isNaN(daysSinceUpdate)
              && (
                <time
                  style={{ color: daysBetweenToColor(daysSinceUpdate) }}
                  className="daysSinceUpdate"
                >
                  <HistoryIcon />
                  {daysSinceUpdate}
                  <span className={zoomLevel < 60 ? 'hidden' : ''}>
                    {' days since last update.'}
                  </span>
                </time>
              )}
            </div>
          </button>
        )}
      </Draggable>
    </li>
  );
  /* eslint-enable react/jsx-props-no-spreading */
}
Manuscript.propTypes = {
  manuscript: MANUSCRIPT_PROPS.isRequired,
  onClick: propTypes.func.isRequired,
  zoomLevel: propTypes.number.isRequired,
  index: propTypes.number.isRequired,
};
