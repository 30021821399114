import React from 'react';
import propTypes from 'prop-types';

import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

import { FILTERS_PROPS } from './dashboardProps';
import './Dashboard.css';

export default function SearchAndFilterButtons({
  filters,
  setFilters,
  filterPanelOpen,
  setFilterPanelOpen,
}) {
  return (
    <div className="settings-buttons">
      <div className="search">
        <SearchIcon />
        <input
          className="white-background"
          type="text"
          value={filters.search}
          onChange={({ target }) => {
            setFilters({
              ...filters,
              search: target.value,
            });
          }}
        />
      </div>
      <button
        type="button"
        onClick={() => { setFilterPanelOpen(!filterPanelOpen); }}
        className={`filter-button white-background ${filterPanelOpen ? 'open' : 'closed'}`}
      >
        <ClearIcon className="clear" />
        <FilterListIcon className="filter" />
      </button>
    </div>
  );
}
SearchAndFilterButtons.propTypes = {
  filters: FILTERS_PROPS.isRequired,
  setFilters: propTypes.func.isRequired,
  filterPanelOpen: propTypes.bool.isRequired,
  setFilterPanelOpen: propTypes.func.isRequired,
};
