import React from 'react';
import propTypes from 'prop-types';
import { Droppable } from 'react-beautiful-dnd';

import { MANUSCRIPT_PROPS, FILTERS_PROPS } from './dashboardProps';
import './Dashboard.css';
import Manuscript from './Manuscript';

import manuscriptOverdue from './manuscriptOverdue';

function renderName(name) { return (name ? name.replaceAll('-', ' ') : ''); }
function getManuscripts(manuscripts, status) {
  return manuscripts.filter((manuscript) => manuscript.state === status);
}
function arraysOverlap(array1, array2) {
  if (array1.findIndex((element) => array2.includes(element)) !== -1) return true;
  if (array2.findIndex((element) => array1.includes(element)) !== -1) return true;
  return false;
}
function matchesFilter(manuscript, filters) {
  const matchesSearch = filters.search
    ? manuscript.title.toLowerCase().includes(filters.search.toLowerCase())
    : false;
  const matchesStatuses = filters.statuses.length
    ? filters.statuses.includes(manuscript.state)
    : false;
  const matchesAuthors = filters.authors.length
    ? arraysOverlap(filters.authors, manuscript.authors)
    : false;
  const matchesReviewers = filters.reviewers.length
    ? arraysOverlap(filters.reviewers, manuscript.reviewers.map(({ name }) => name))
    : false;
  return matchesSearch
    || matchesStatuses
    || matchesAuthors
    || matchesReviewers;
}
function sortManuscripts(a, b) {
  if (a.timeUpdated > b.timeUpdated) return 1;
  if (a.timeUpdated < b.timeUpdated) return -1;
  return 0;
}
function zoomLevelToCh(zoomLevel) {
  return Math.floor((zoomLevel / 5) + 25);
}

export default function Column({
  manuscripts,
  status,
  setViewedManuscript,
  filters,
  zoomLevel,
}) {
  const filterApplied = filters.search
    || filters.statuses.length
    || filters.authors.length
    || filters.reviewers.length;
  const anyManuscriptOverdue = manuscripts
    .filter((manuscript) => manuscript.state === status)
    .find(manuscriptOverdue);
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <section
      className="dashboard-column"
      style={{ '--manuscript-width': `${zoomLevelToCh(zoomLevel)}ch` }}
    >
      <h2 className={anyManuscriptOverdue ? 'overdue' : ''}>{renderName(status)}</h2>
      <Droppable droppableId={status}>
        {(provided, snapshot) => (
          <ul ref={provided.innerRef} {...provided.droppableProps} style={snapshot ? {} : {}}>
            {getManuscripts(manuscripts, status).sort(sortManuscripts).map((manuscript, index) => {
              if (filterApplied && !matchesFilter(manuscript, filters)) return null;
              return (
                <Manuscript
                  key={manuscript.name}
                  manuscript={manuscript}
                  onClick={() => { setViewedManuscript(manuscript); }}
                  zoomLevel={zoomLevel}
                  index={index}
                />
              );
            })}
            {provided.placeholder}
          </ul>
        )}
      </Droppable>
    </section>
  );
  /* eslint-enable react/jsx-props-no-spreading */
}
Column.propTypes = {
  status: propTypes.string.isRequired,
  setViewedManuscript: propTypes.func.isRequired,
  filters: FILTERS_PROPS.isRequired,
  manuscripts: propTypes.arrayOf(MANUSCRIPT_PROPS).isRequired,
  zoomLevel: propTypes.number.isRequired,
};
