import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import propTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  headersToColumns,
  resultsToArray,
  questionsToQueryString,
  queryStringToObject,
} from '../../../core/Utils/objectUtils';

import Questions from '../../../core/Input/Questions';

import ContentBox from '../../../core/Display/ContentBox';
import LoadingAnimation from '../../../core/Display/LoadingAnimation';
import Table from '../../../core/Display/Table';

import {
  getCollectionInfo,
  // === Glossary ===
  getAddGlossaryItemForm,
  addGlossaryItem,
  editGlossaryItem,
  deleteGlossaryItem,
  // === Bibliography ===
  getAddBibliographyItemForm,
  addBibliographyItem,
  editBibliographyItem,
  deleteBibliographyItem,
  // === Journal People ===
  getAddJournalPeopleItemForm,
  addJournalPeopleItem,
  editJournalPeopleItem,
  deleteJournalPeopleItem,
  // === Gross Issuance  ===
  getAddGrossIssuanceItemForm,
  addGrossIssuanceItem,
  editGrossIssuanceItem,
  deleteGrossIssuanceItem,
  // === Servicer Performance ===
  getAddServicerPerformanceItemForm,
  addServicerPerformanceItem,
  editServicerPerformanceItem,
  deleteServicerPerformanceItem,
} from '../../../utils/networkUtils';
import { setTitle } from '../../../utils/browserUtils';
import UserContext from '../../../UserContext';
import BIBLIOGRAPHY_BACKGROUND from '../../../images/sfa-variant/SFA_biblioBG.jpg';
import GLOSSARY_BACKGROUND from '../../../images/sfa-variant/SFA_glossaryBG.jpg';
import SFA_REPORT_BACKGROUND_IMAGE from '../../../images/SFA_reporthero_2X.png';

import BackgroundImage from '../../Common/BackgroundImage';
import ProgressIndicator from '../../Common/ProgressIndicator';
import Graph from '../../Common/Graph';
import { getOptions, getResults } from '../../Collection/Collection';
import SecurityContext from '../../SecurityContext';

import CouponGraphs from './CouponGraphs';
import './DataSearch.css';
import MarkdownParser from '../../../core/Transform/MarkdownParser';

const PROTOCOL_MAPPINGS = {
  People: '/Journal/people',
};
const nameToProtocol = (name) => {
  if (PROTOCOL_MAPPINGS[name]) return PROTOCOL_MAPPINGS[name];
  const regex = / /g;
  const reformattedName = name.replace(regex, '');
  return `/${reformattedName}`;
};
const VALID_PAGE_NAMES = [
  'Glossary',
  'Bibliography',
  'People',
  'Gross Issuance',
  'Servicer Performance',
];
const VALID_PAGE_PROTOCOLS = VALID_PAGE_NAMES.map(nameToProtocol);

function getBackgroundImage(pageName) {
  switch (pageName) {
    case 'Glossary': return GLOSSARY_BACKGROUND;
    case 'Bibliography': return BIBLIOGRAPHY_BACKGROUND;
    case 'Gross Issuance': return SFA_REPORT_BACKGROUND_IMAGE;
    case 'Servicer Performance': return SFA_REPORT_BACKGROUND_IMAGE;
    default: return undefined;
  }
}

function Options({ pageProtocol, onSubmit }) {
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState();
  const setOption = (fieldName, value) => setOptions({
    ...options,
    [fieldName]: { ...options[fieldName], value },
  });
  const { receivedURLs } = useContext(UserContext);
  const location = useLocation();

  useEffect(
    () => {
      if (!receivedURLs) return;
      getOptions(pageProtocol, setOptions);
    },
    [receivedURLs],
  );
  const populateInitialOptions = () => {
    if (!location.search) return;
    const searchQueryObject = queryStringToObject(location.search);
    const keys = Object.keys(searchQueryObject);
    keys.forEach((key) => {
      options[key].value = searchQueryObject[key];
    });
    setOptions({ ...options });
  };
  useEffect(
    () => {
      if (!options) return;
      if (loading) populateInitialOptions();
      setLoading(false);
    },
    [options],
  );

  if (loading) return <ContentBox>Loading...</ContentBox>;
  return (
    <ContentBox classes={['search-options']}>
      <Questions
        questions={options}
        responseSetter={setOption}
        horizontal
        onSubmit={() => { onSubmit(options); }}
        submitButtonText="Search"
      />
    </ContentBox>
  );
}
Options.propTypes = {
  onSubmit: propTypes.func.isRequired,
  pageProtocol: propTypes.oneOf(VALID_PAGE_PROTOCOLS).isRequired,
};

function getHeaders(pageProtocol, setHeaders) {
  getCollectionInfo(pageProtocol, 'fields').then((data) => {
    const [firstKey] = Object.keys(data);
    const headers = data[firstKey];
    setHeaders(headers);
  });
}

const ACTION_FUNCTIONS = {
  Glossary: {
    form: getAddGlossaryItemForm,
    add: addGlossaryItem,
    edit: editGlossaryItem,
    delete: deleteGlossaryItem,
  },
  Bibliography: {
    form: getAddBibliographyItemForm,
    add: addBibliographyItem,
    edit: editBibliographyItem,
    delete: deleteBibliographyItem,
  },
  People: {
    form: getAddJournalPeopleItemForm,
    add: addJournalPeopleItem,
    edit: editJournalPeopleItem,
    delete: deleteJournalPeopleItem,
  },
  'Gross Issuance': {
    form: getAddGrossIssuanceItemForm,
    add: addGrossIssuanceItem,
    edit: editGrossIssuanceItem,
    delete: deleteGrossIssuanceItem,
  },
  'Servicer Performance': {
    form: getAddServicerPerformanceItemForm,
    add: addServicerPerformanceItem,
    edit: editServicerPerformanceItem,
    delete: deleteServicerPerformanceItem,
  },
};

function getActionFunction(pageName, action) { return ACTION_FUNCTIONS[pageName][action]; }

function getTableOptions(pageName, permissions) {
  return {
    noEdit: true,
    allowAdd: {
      isEnabled: permissions.add,
      form: getActionFunction(pageName, 'form'),
      onSubmit: getActionFunction(pageName, 'add'),
    },
    allowEdit: {
      isEnabled: permissions.edit,
      form: getActionFunction(pageName, 'form'),
      onSubmit: getActionFunction(pageName, 'edit'),
    },
    allowDelete: {
      isEnabled: permissions.delete,
      form: [],
      onSubmit: getActionFunction(pageName, 'delete'),
    },
  };
}

function getKeyFieldName(headers) {
  return Object.keys(headers).find((key) => headers[key].isKeyField);
}

function securityKey(pageName) {
  if (pageName === 'People') return 'journal';
  return pageName;
}

function getInitialFilters(pageName) {
  switch (pageName) {
    case 'Gross Issuance': return { Columns: ['Current Coupon'] };
    case 'Servicer Performance': return { Columns: ['Current Coupon'] };
    default: return undefined;
  }
}

function formatColumnsForGraph(columns) {
  const validTypes = ['float', 'int'];
  const dateColumnName = 'Date Bucket';
  const graphableColumns = columns.filter(({ name, type }) => validTypes.includes(type)
    || name === dateColumnName);
  return graphableColumns.map((column) => ({
    ...column,
    tags: {
      Columns: column.name,
    },
  }));
}

function formatData(headers, results) {
  if (!headers || !results) return {};
  const columns = headersToColumns(headers);
  const numericColumns = formatColumnsForGraph(columns);
  const rows = resultsToArray(results, headers);
  return { columns, numericColumns, rows };
}

function desiredResource(pageProtocol) {
  if (pageProtocol === nameToProtocol('People')) return 'read';
  return undefined;
}

function Results({ pageProtocol, pageName, graphEnabled }) {
  const [loading, setLoading] = useState(true);
  const [headers, setHeaders] = useState();
  const [results, setResults] = useState();
  const [permissions, setPermissions] = useState({
    add: undefined,
    edit: undefined,
    delete: undefined,
  });
  const { receivedURLs } = useContext(UserContext);
  const { page, setPage, isValidUser } = useContext(SecurityContext);
  const location = useLocation();

  const setPermission = (action, enabled) => {
    permissions[action] = enabled;
    setPermissions({ ...permissions });
  };
  useEffect(
    () => {
      if (!receivedURLs) return;
      getHeaders(pageProtocol, setHeaders);
    },
    [receivedURLs],
  );
  useEffect(
    () => {
      if (!receivedURLs) return;
      if (page !== securityKey(pageName)) {
        setPage(securityKey(pageName));
        return;
      }
      isValidUser('create', securityKey(pageName))
        .then((enabled) => setPermission('add', enabled))
        .catch(() => setPermission('add', false));
      isValidUser('update', securityKey(pageName))
        .then((enabled) => setPermission('edit', enabled))
        .catch(() => setPermission('edit', false));
      isValidUser('delete', securityKey(pageName))
        .then((enabled) => setPermission('delete', enabled))
        .catch(() => setPermission('delete', false));
    },
    [receivedURLs, page],
  );
  useEffect(
    () => {
      if (!receivedURLs) return;
      setResults();
      getResults(pageProtocol, setResults, location, desiredResource(pageProtocol));
    },
    [receivedURLs, location.search],
  );
  const loadingProgress = useMemo(
    () => [
      receivedURLs,
      headers !== undefined,
      results !== undefined,
      permissions.add !== undefined,
      permissions.edit !== undefined,
      permissions.delete !== undefined,
    ],
    [
      receivedURLs,
      headers,
      results,
      permissions.add,
      permissions.edit,
      permissions.delete,
    ],
  );
  useEffect(
    () => {
      for (let index = 0; index < loadingProgress.length; index += 1) {
        if (!loadingProgress[index]) return;
      }
      setLoading(false);
    },
    [loadingProgress],
  );
  const { columns, numericColumns, rows } = useMemo(
    () => formatData(headers, results),
    [headers, results],
  );

  if (loading) {
    return (
      <ContentBox classes={['outlined', 'search-results']}>
        <ProgressIndicator
          steps={[
            'Retrieving endpoint map',
            'Retrieving column headers',
            'Retrieving table data',
            'Checking permission to add entries',
            'Checking permission to edit entries',
            'Checking permission to delete entries',
          ]}
          progress={loadingProgress}
        />
      </ContentBox>
    );
  }
  const SOURCE_TEXT = 'Source: [RiskSpan](https://riskspan.com/)';
  return (
    <>
      {pageName === 'Gross Issuance' && results !== undefined && (
        <CouponGraphs rows={rows} />
      )}
      <ContentBox classes={['outlined', 'search-results']}>
        <h2>SEARCH RESULTS</h2>
        {results === undefined && <LoadingAnimation />}
        <Table
          columns={columns}
          rows={rows}
          options={getTableOptions(pageName, permissions)}
          keyFieldName={getKeyFieldName(headers)}
        />
        {graphEnabled && (
          <>
            <br />
            <div className="datasearch-graph-wrapper">
              <Graph
                columns={numericColumns}
                rows={rows}
                xAxisID="factor_date"
                initialFilters={getInitialFilters(pageName)}
              />
            </div>
          </>
        )}
        {results && (pageName === 'Gross Issuance' || pageName === 'Servicer Performance') && (
          <div className="source-text">
            <MarkdownParser md={SOURCE_TEXT} />
          </div>
        )}
      </ContentBox>
    </>
  );
}
Results.propTypes = {
  pageName: propTypes.oneOf(VALID_PAGE_NAMES).isRequired,
  pageProtocol: propTypes.oneOf(VALID_PAGE_PROTOCOLS).isRequired,
  graphEnabled: propTypes.bool.isRequired,
};

function Header({ pageName, level = 1, hide = false }) {
  if (hide) return null;
  switch (level) {
    case 6: return <h6>{pageName}</h6>;
    case 5: return <h5>{pageName}</h5>;
    case 4: return <h4>{pageName}</h4>;
    case 3: return <h3>{pageName}</h3>;
    case 2: return <h2>{pageName}</h2>;
    default: return <h1>{pageName}</h1>;
  }
}
Header.propTypes = {
  pageName: propTypes.oneOf(VALID_PAGE_NAMES).isRequired, // The name to display to the user
  level: propTypes.oneOf([1, 2, 3, 4, 5, 6]),
  hide: propTypes.bool,
};

export default function DataSearch({
  pageName,
  headerLevel = 1,
  headerHidden = false,
  graphEnabled = false,
}) {
  const pageProtocol = nameToProtocol(pageName);
  const navigate = useNavigate();
  setTitle(pageName);

  const onSubmit = (options) => {
    const queryString = questionsToQueryString(options);
    navigate(`./${queryString}`);
  };

  return (
    <BackgroundImage
      image={getBackgroundImage(pageName)}
      color="var(--color-primary)"
      className={`sfa-data-search ${pageName}`}
    >
      <Header pageName={pageName} level={headerLevel} hide={headerHidden} />
      <Options pageProtocol={pageProtocol} onSubmit={onSubmit} />
      <div style={{ height: '5rem' }} />
      <Results
        graphEnabled={graphEnabled}
        pageProtocol={pageProtocol}
        pageName={pageName}
      />
    </BackgroundImage>
  );
}

DataSearch.propTypes = {
  pageName: propTypes.oneOf(VALID_PAGE_NAMES).isRequired, // The name to display to the user
  headerLevel: propTypes.oneOf([1, 2, 3, 4, 5, 6]),
  headerHidden: propTypes.bool,
  graphEnabled: propTypes.bool,
};
