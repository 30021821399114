/* eslint import/prefer-default-export: off */
export const LOREM = `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
incididunt ut labore et dolore magna aliqua. In metus vulputate eu scelerisque felis imperdiet.
Nunc faucibus a pellentesque sit amet porttitor eget dolor. Euismod in pellentesque massa placerat.
Turpis massa sed elementum tempus egestas. Est lorem ipsum dolor sit amet consectetur adipiscing.
Praesent tristique magna sit amet purus gravida. Nam libero justo laoreet sit amet cursus sit.
Convallis convallis tellus id interdum velit laoreet id. Tincidunt nunc pulvinar sapien et ligula
ullamcorper malesuada proin. Sollicitudin tempor id eu nisl nunc mi ipsum faucibus. Eget aliquet
nibh praesent tristique magna sit amet purus gravida. Vitae suscipit tellus mauris a diam maecenas.
Odio facilisis mauris sit amet massa.`;
