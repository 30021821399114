import React, { useState } from 'react';
import propTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import { get } from 'axios';
import '../Tooltip/Tooltip.css';

import MarkdownParser from '../../Transform/MarkdownParser';
import Button from '../../Input/Button';
import LoadingAnimation from '../LoadingAnimation';

/* eslint no-unused-vars: warn */

const errors = {
  noData: 'No data was found.',
};

function keyToString(key) {
  switch (key) {
    case 'more_info': return '';
    case 'fips': return 'FIPS: ';
    case 'area_sqm': return 'Area (Square Miles): ';
    case 'csa': return 'CSA: ';
    case 'pop2020': return 'Population (2020): ';
    default:
  }
  const capitalized = key
    .split('_')
    .map((word) => (
      word.slice(0, 1).toUpperCase()
        .concat(word.slice(1))
    ))
    .join(' ');
  return `${capitalized}: `;
}

function objectToText(object) {
  if (!object) return errors.noData;
  const keys = Object.keys(object);
  return keys.map((key) => <MarkdownParser md={`${keyToString(key)}${object[key]}`} />);
}

function DynamicTooltip({
  link,
  children,
  tooltipIcon = '*',
  text = null,
}) {
  const [content, setContent] = useState();

  const onHover = () => {
    if (content) return;
    get(link)
      .then(({ data }) => { setContent(data); })
      .catch(() => { setContent(null); });
  };

  const display = content !== undefined
    ? objectToText(content)
    : <LoadingAnimation />;
  return (
    <div
      className="dynamic-tooltip"
    >
      {children}
      <Tooltip
        title={text || display}
        onOpen={onHover}
        arrow
        componentsProps={{
          tooltip: {
            sx: {
              backgroundColor: '#ffffff',
              color: '#000000',
              border: '1px solid #000000',
              fontSize: '1rem',
            },
          },
        }}
      >
        <div>
          <Button variant="icon" classes={['info-tooltip-button']}>
            {tooltipIcon}
          </Button>
        </div>
      </Tooltip>
    </div>
  );
}

export default DynamicTooltip;

DynamicTooltip.propTypes = {
  tooltipIcon: propTypes.string,
  link: propTypes.string.isRequired,
  children: propTypes.oneOfType([
    propTypes.node,
    propTypes.arrayOf(propTypes.node),
  ]).isRequired,
  text: propTypes.string,
};
