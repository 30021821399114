import { ON_STAGING } from '../constants';

export const BASE_URL = ON_STAGING
  ? localStorage.getItem('backendURL')
  : process.env.REACT_APP_BACKEND_BASE_URL;
const URL_MAP = {
  BASE_URL,
};

const API_SUBSET_VAL = process.env.REACT_APP_API_SUBSET;
const API_SUBSET_FLD = 'subset';
const AUTH_KEY_VERIFICATION = 'AuthKey';
const SAVE_REPORT = 'SaveReport';
const DELETE_REPORT = 'DeleteReport';
const LIST_REPORTS = 'ListReports';
const APIS = 'APIs';
const CAN_EDIT = 'ValidEditor';
const ADDAPI_FORMS = 'AddAPI-form-list';
const ADDAPI_QUESTIONS = 'AddAPI-form';
const ADDAPI_CREATE = 'CreateAPI';
const API_SEMANTIC_SEARCH = 'SemanticSearchAPIs';
const S_TYPES = 'STypes';
const CATS = 'Categories';
const DATE_RANGE = 'DateRange';
const RECIP_LIST_REPLACE = 'ReplaceRecip';
const HMDA_STATE_FORM = 'HMDAStateForm';
const HMDA_STATE_QUERY = 'HMDAStateQuery';
const HMDA_AGGREGATE_FORM = 'HMDAAggrForm';
const HMDA_AGGREGATE_QUERY = 'HMDAAggrQueryMap';
const HMDA_AGGREGATE_FIELDS = 'HMDAAggrFields';
const HMDA_COL_INFO = 'HMDAFieldsList';
const MENUS = 'Menus';
const DATA_SRC_FORM = 'DataSourceForm';
const REPORT_DESTINATIONS = 'RptDests';
const DATA_SOURCE_FIELDS = 'DataSourceFields';
const US_STATE_DEBT_REPORT = 'USStateDebtReport';
const US_STATE_DELINQUENCY_REPORT = 'USStateDeliqReport';
const US_STATE_BALANCE_REPORT = 'USStateBalanceReport';
const US_STATE_RESIDENTIAL_BALANCE_REPORT = 'USStateResBalanceReport';
const US_STATE_MULTIFAMILY_BALANCE_REPORT = 'USStateMultiBalanceReport';
const US_STATE_YEARLY_FIELDS = 'USStateYearlyFields';
const US_STATE_RETRIEVE = 'USStateRetrieve';
const US_STATE_FIELDS = 'USStateFields';

const ADD_GLOSSARY_ENTRY = 'GlossaryAdd';
const ADD_GLOSSARY_ENTRY_FORM = 'GlossaryAddForm';
const EDIT_GLOSSARY_ENTRY = 'GlossaryUpdate';
const DELETE_GLOSSARY_ENTRY = 'GlossaryDelete';

const ADD_BIBLIOGRAPHY_ENTRY = 'BibliographyAdd';
const ADD_BIBLIOGRAPHY_ENTRY_FORM = 'BibliographyAddForm';
const EDIT_BIBLIOGRAPHY_ENTRY = 'BibliographyUpdate';
const DELETE_BIBLIOGRAPHY_ENTRY = 'BibliographyDelete';

const SECURITY_MANAGER = 'SecurityManager';
const IS_VALID_USER = 'IsValidUser';

const JOURNAL_TEXT_UPDATE = 'JournalTextUpdate';
const JOURNAL_TEXT_FIELDS = 'JournalTextFields';
const JOURNAL_TEXT_FORM = 'JournalTextForm';
const JOURNAL_TEXT_MAP = 'JournalTextMap';

const JOURNAL_SUBMISSION = 'JournalManuscriptAdd';
const JOURNAL_SUBMISSION_FORM = 'JournalManuscriptAddForm';
const JOURNAL_MANUSCRIPTS_MAP = 'JournalManuscriptRead';
const JOURNAL_MANUSCRIPTS_ACTION_DO = 'JournalManuscriptSendAction';
const JOURNAL_MANUSCRIPTS_ACTION_READ = 'JournalManuscriptActionsRead';
const JOURNAL_DASH_COLUMNS_MAP = 'JournalManuscriptColumnsRead';
const JOURNAL_STATES_READ = 'JournalManuscriptStatesRead';

const JOURNAL_MASTHEAD = 'JournalPeopleMasthead';

const ADD_JOURNAL_PEOPLE_ENTRY = 'JournalPeopleAdd';
const ADD_JOURNAL_PEOPLE_ENTRY_FORM = 'JournalPeopleAddForm';
const JOURNAL_PEOPLE_READ = 'JournalPeopleRead';
const EDIT_JOURNAL_PEOPLE_ENTRY = 'JournalPeopleEdit';
const DELETE_JOURNAL_PEOPLE_ENTRY = 'JournalPeopleDelete';

function getURL(name, options = {}) {
  if (!name) return null;
  if (!URL_MAP) return null;
  if (!URL_MAP[name]) {
    if (options.errorWhenMissing) {
      throw new Error(`${name} is not in endpoint map.`);
    }
    return null;
  }
  if (typeof URL_MAP[name] !== 'string') return null;
  const prefix = URL_MAP.BASE_URL;
  const postfix = URL_MAP[name];
  const infix = postfix[0] === '/' ? '' : '/';
  return `${prefix}${infix}${postfix}`;
}

function getAuthURL() {
  return getURL(AUTH_KEY_VERIFICATION);
}

function getSaveReportURL() {
  return getURL(SAVE_REPORT);
}

function getDeleteReportURL() {
  return getURL(DELETE_REPORT);
}

function getReportListURL() {
  return getURL(LIST_REPORTS);
}

function getCanEditURL() {
  return getURL(CAN_EDIT);
}

function getAPIsURL() {
  let ret = getURL(APIS);
  if (API_SUBSET_VAL) {
    ret += `?${API_SUBSET_FLD}=${API_SUBSET_VAL}`;
  }
  return ret;
}

function getAddAPIFormsURL() {
  return getURL(ADDAPI_FORMS);
}

function getAddAPIQuestionsURL() {
  return getURL(ADDAPI_QUESTIONS);
}

function getAddAPICreateURL() {
  return getURL(ADDAPI_CREATE);
}

function getAPISemanticSearchURL() {
  return getURL(API_SEMANTIC_SEARCH);
}

function getSTypesURL(dataSourceIDs) {
  if (dataSourceIDs) return `${getURL(S_TYPES)}?api_ids=${dataSourceIDs}`;
  return getURL(S_TYPES);
}

function getCatsURL() {
  return getURL(CATS);
}

function getDateRangeURL() {
  return getURL(DATE_RANGE);
}

function getRecipReplaceURL() {
  return getURL(RECIP_LIST_REPLACE);
}

function getHMDAStateFormURL() {
  return getURL(HMDA_STATE_FORM);
}

function getHMDAStateQueryURL() {
  return getURL(HMDA_STATE_QUERY);
}

function getHMDAAggregateFormURL() {
  return getURL(HMDA_AGGREGATE_FORM);
}

function getHMDAAggregateQueryURL() {
  return getURL(HMDA_AGGREGATE_QUERY);
}

function getHMDAAggregateFieldsURL() {
  return getURL(HMDA_AGGREGATE_FIELDS);
}

function getHMDAColumnInfoURL() {
  return getURL(HMDA_COL_INFO);
}

const collectionResources = [
  'form',
  'fields',
  'map',
  'read',
];
function getCollectionURL(endpoint, resource) {
  if (typeof endpoint !== 'string') {
    throw new Error(`Invalid collection endpoint ${endpoint}`);
  }
  if (!collectionResources.includes(resource)) {
    throw new Error(`Invalid collection resource ${resource}`);
  }
  const url = `${URL_MAP.BASE_URL}${endpoint}/${resource}`;
  if (!url) throw new Error('Invalid url');
  return url;
}

function getMenusURL() {
  return getURL(MENUS);
}

function getDataSrcFormURL() {
  return getURL(DATA_SRC_FORM);
}

function getReportDestinationsURL() {
  return getURL(REPORT_DESTINATIONS);
}

function getDataSourceFieldsURL() {
  return getURL(DATA_SOURCE_FIELDS);
}

function getUSStateReportURL(type) {
  switch (type) {
    case 'debt': return getURL(US_STATE_DEBT_REPORT);
    case 'delinquency': return getURL(US_STATE_DELINQUENCY_REPORT);
    case 'balance': return getURL(US_STATE_BALANCE_REPORT);
    case 'residential_balance': return getURL(US_STATE_RESIDENTIAL_BALANCE_REPORT);
    case 'multifamily_balance': return getURL(US_STATE_MULTIFAMILY_BALANCE_REPORT);
    default: throw new Error(`${type} is not a valid report type.`);
  }
}

function getUSStateReportFieldsURL() {
  return getURL(US_STATE_YEARLY_FIELDS);
}

function getUSStateRetrieveURL() {
  return getURL(US_STATE_RETRIEVE);
}

function getUSStateFieldsURL() {
  return getURL(US_STATE_FIELDS);
}

export function getAddGlossaryEntryURL(action) {
  switch (action) {
    case 'CREATE': return getURL(ADD_GLOSSARY_ENTRY);
    case 'RETRIEVE': return getURL(ADD_GLOSSARY_ENTRY_FORM);
    case 'UPDATE': return getURL(EDIT_GLOSSARY_ENTRY);
    case 'DELETE': return getURL(DELETE_GLOSSARY_ENTRY);
    default: throw new Error(`getAddGlossaryEntryURL called with invalid action: ${action}`);
  }
}

export function getAddBibliographyEntryURL(action) {
  switch (action) {
    case 'CREATE': return getURL(ADD_BIBLIOGRAPHY_ENTRY);
    case 'RETRIEVE': return getURL(ADD_BIBLIOGRAPHY_ENTRY_FORM);
    case 'UPDATE': return getURL(EDIT_BIBLIOGRAPHY_ENTRY);
    case 'DELETE': return getURL(DELETE_BIBLIOGRAPHY_ENTRY);
    default: throw new Error(`getAddBibliographyEntryURL called with invalid action: ${action}`);
  }
}

export function getAddJournalPeopleEntryURL(action) {
  switch (action) {
    case 'CREATE': return getURL(ADD_JOURNAL_PEOPLE_ENTRY);
    case 'RETRIEVE': return getURL(ADD_JOURNAL_PEOPLE_ENTRY_FORM);
    case 'UPDATE': return getURL(EDIT_JOURNAL_PEOPLE_ENTRY);
    case 'DELETE': return getURL(DELETE_JOURNAL_PEOPLE_ENTRY);
    default: throw new Error(`getAddJournalPeopleEntryURL called with invalid action: ${action}`);
  }
}

export function getJournalPeopleReadURL() { return getURL(JOURNAL_PEOPLE_READ); }

export function getSecurityManagerURL() { return getURL(SECURITY_MANAGER); }
export function getIsValidUserURL() { return getURL(IS_VALID_USER); }

export function getJournalTextURL(resource) {
  switch (resource) {
    case 'UPDATE': return getURL(JOURNAL_TEXT_UPDATE);
    case 'FIELDS': return getURL(JOURNAL_TEXT_FIELDS);
    case 'FORM': return getURL(JOURNAL_TEXT_FORM);
    case 'MAP': return getURL(JOURNAL_TEXT_MAP);
    default: throw new Error(`getJournalTextURL called with invalid resource: ${resource}`);
  }
}

export function getJournalSubmissionFormURL() {
  return getURL(JOURNAL_SUBMISSION_FORM, { errorWhenMissing: true });
}

export function getJournalSubmissionURL() {
  return getURL(JOURNAL_SUBMISSION, { errorWhenMissing: true });
}

export function getJournalManuscriptsMapURL() {
  return getURL(JOURNAL_MANUSCRIPTS_MAP, { errorWhenMissing: true });
}

export function getJournalManuscriptsActionDoURL() {
  return getURL(JOURNAL_MANUSCRIPTS_ACTION_DO, { errorWhenMissing: true });
}

export function getJournalManuscriptsActionReadURL() {
  return getURL(JOURNAL_MANUSCRIPTS_ACTION_READ, { errorWhenMissing: true });
}

export function getJournalDashColumnsURL() {
  return getURL(JOURNAL_DASH_COLUMNS_MAP, { errorWhenMissing: true });
}

export function getJournalStatesReadURL() {
  return getURL(JOURNAL_STATES_READ, { errorWhenMissing: true });
}

export function getJournalMastheadURL() {
  return getURL(JOURNAL_MASTHEAD, { errorWhenMissing: true });
}

export {
  URL_MAP,
  getURL,
  getSaveReportURL,
  getDeleteReportURL,
  getReportListURL,
  getAPIsURL,
  getAuthURL,
  getCanEditURL,
  getAddAPIFormsURL,
  getAddAPIQuestionsURL,
  getAddAPICreateURL,
  getAPISemanticSearchURL,
  getSTypesURL,
  getCatsURL,
  getDateRangeURL,
  getRecipReplaceURL,
  getHMDAStateFormURL,
  getHMDAStateQueryURL,
  getHMDAAggregateFormURL,
  getHMDAAggregateQueryURL,
  getHMDAAggregateFieldsURL,
  getHMDAColumnInfoURL,
  getCollectionURL,
  getMenusURL,
  getDataSrcFormURL,
  getReportDestinationsURL,
  getDataSourceFieldsURL,
  getUSStateReportURL,
  getUSStateReportFieldsURL,
  getUSStateRetrieveURL,
  getUSStateFieldsURL,
};
