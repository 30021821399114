import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import AddIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import { equivalent, optionsToArray, formatQuestions } from '../../Utils/objectUtils';

import Button from '../Button';
import DateField from '../DateField';
import Dropdown from '../Dropdown';
import FilePicker from '../FilePicker';
import MultipleSelect from '../MultipleSelect';
import NumberField from '../NumberField';
import TextField from '../TextField';
import Label from '../Label';

import './Questions.css';

const DEF_TEXT_BOX_ROWS = 3;

/*
  eslint camelcase: off, no-param-reassign: off
*/

function QuestionText({ question, onChange }) {
  const {
    question: label,
    value = '',
    input_type: inputType,
    fld_len: givenFieldLength,
    error,
    fld_nm: fieldName,
    helperText,
    full_width: fullWidth,
    disabled,
  } = question;
  const fieldLength = givenFieldLength;
  const multilineLength = window.innerWidth / 10;
  const multiline = fieldLength > multilineLength;
  const minRows = DEF_TEXT_BOX_ROWS;
  const width = multiline ? multilineLength : fieldLength;

  if (!label) return null;
  return (
    <TextField
      helperText={helperText}
      error={error}
      label={label}
      id={fieldName}
      value={value}
      type={inputType}
      multiline={multiline}
      minRows={minRows}
      width={width}
      size={fieldLength ? fieldLength.toString() : ''}
      onChange={(e) => onChange(e.target.value)}
      fullWidth={multiline || fullWidth}
      disabled={disabled}
    />
  );
}

function QuestionDate({ question, onChange }) {
  const {
    question: label,
    value,
    min,
    max,
    error,
    full_width: fullWidth,
    disabled,
    helperText,
  } = question;
  return (
    <DateField
      error={error}
      label={label}
      value={value}
      minDate={min}
      maxDate={max}
      onChange={(e) => { onChange(e); }}
      fullWidth={fullWidth}
      disabled={disabled}
      helperText={helperText}
    />
  );
}

function QuestionFile({ question, onChange }) {
  const {
    question: label,
    error,
    full_width: fullWidth,
    disabled,
    helperText,
    fileTypes,
  } = question;
  return (
    <FilePicker
      error={error}
      label={label}
      onChange={(e) => { onChange(e.target.files[0]); }}
      fullWidth={fullWidth}
      disabled={disabled}
      helperText={helperText}
      fileTypes={fileTypes}
    />
  );
}

export const DEFAULT = 'default';

function ensureValidSelection(
  value,
  options,
  defaultValue,
  onChange,
) {
  const fallback = defaultValue !== undefined
    ? DEFAULT
    : options[0].id;
  if (value === undefined) { onChange(fallback); return; }
  const selectionValid = options.find(({ id }) => id === value);
  if (!selectionValid) onChange(fallback);
}

function doesDefaultValueExist(options, defaultValue) {
  if (typeof options !== 'object') return false;

  const keys = Array.isArray(options)
    ? options.map(({ id }) => id)
    : Object.keys(options);
  return keys.includes(defaultValue);
}

function QuestionDropdown({ question, onChange, parent }) {
  const {
    question: label,
    value,
    default: defaultValue,
    error,
    fld_nm: fieldName,
    full_width: fullWidth,
    disabled,
    helperText,
  } = question;
  let {
    choices: options,
  } = question;

  const determineChildOptions = () => {
    if (!Object.keys(parent).length) return options;
    if (!parent.value) return [];
    if (Array.isArray(options) && !options.length) return [];
    if (!options[parent.value]) return [];
    return options[parent.value];
  };

  options = determineChildOptions();
  const defaultValueExists = doesDefaultValueExist(options, defaultValue);
  if (defaultValue && !defaultValueExists) {
    options = { default: defaultValue, ...options };
  }

  if (!Array.isArray(options)) {
    options = Object.keys(options).map((id) => ({
      id,
      name: options[id],
    }));
  }
  useEffect(() => {
    ensureValidSelection(value, options, defaultValue, onChange);
  }, [value]);

  return (
    <Dropdown
      error={error}
      label={label}
      id={fieldName}
      options={options}
      onChange={(e) => { onChange(e.target.value); }}
      value={value}
      fullWidth={fullWidth}
      disabled={disabled}
      helperText={helperText}
    />
  );
}

function valueToArray(value, options) {
  if (Array.isArray(value)) return value;
  if (value === undefined) return [];
  if (value.toLowerCase() === 'all') return optionsToArray(options).map(({ id }) => id);
  if (value.toLowerCase() === 'none') return [];
  if (value.includes(',')) return value.split(',');
  return [value];
}

function getInitialValue(value, options, defaultValue) {
  if (value) return valueToArray(value, options);
  return valueToArray(defaultValue, options);
}

function containsOption(option, options) {
  return options.find(({ id }) => id === option.id);
}

function QuestionCheckboxes({ question, onChange, parent }) {
  const {
    fld_nm: fieldName,
    question: label,
    value,
    default: defaultValue,
    optional,
    full_width: fullWidth,
    disabled,
    helperText,
  } = question;
  let {
    choices: options,
  } = question;

  const determineChildOptions = () => {
    if (!Object.keys(parent).length) return optionsToArray(options);
    if (Array.isArray(options) && !options.length) return [];

    const parentOptions = optionsToArray(parent.options);
    const parentValue = valueToArray(parent.value, parentOptions);

    const parentAll = parentValue.length === parentOptions.length;
    const parentNone = parentValue.length === 0;

    const defaultAll = parent.default.toLowerCase() === 'all';
    if (!defaultAll && parentNone) return [];
    if (!defaultAll && parentAll) return optionsToArray(options);
    if (defaultAll && parentNone) return optionsToArray(options);
    if (defaultAll && parentAll) return optionsToArray(options);

    const keys = parentValue;
    let newOptions = {};
    keys.forEach((key) => {
      const keyOptions = options[key];
      newOptions = { ...newOptions, ...keyOptions };
    });
    return optionsToArray(newOptions);
  };

  options = determineChildOptions();
  const [optionsLastRender, setOptionsLastRender] = useState(options);
  const selectNewOptions = () => {
    const optionsHaveNotChanged = equivalent(options, optionsLastRender);
    if (optionsHaveNotChanged) return;

    const shouldSelectNewOptions = parent.default.toLowerCase() === 'all';
    if (!shouldSelectNewOptions) { setOptionsLastRender(options); return; }

    const optionExistedLastRender = (option) => containsOption(option, optionsLastRender);
    const newOptions = options.filter((option) => !optionExistedLastRender(option));
    const newOptionIDs = newOptions
      .map((option) => option.id)
      .filter((optionID) => !value.includes(optionID));
    onChange([...value, ...newOptionIDs]);
    setOptionsLastRender(options);
  };
  useEffect(selectNewOptions, [options]);

  const ensureValueIsArray = () => {
    const arrayValue = valueToArray(value, options);
    if (equivalent(value, arrayValue)) return;
    onChange(arrayValue);
  };
  useEffect(ensureValueIsArray, [value]);

  return (
    <MultipleSelect
      id={fieldName}
      options={options}
      label={label}
      value={getInitialValue(value, options, defaultValue)}
      defaultValue={defaultValue}
      onChange={(e) => { onChange(e); }}
      optional={optional}
      fullWidth={fullWidth}
      disabled={disabled}
      helperText={helperText}
    />
  );
}

function QuestionNumeric({ question, onChange }) {
  const {
    question: label,
    value = undefined,
    error,
    fld_nm: fieldName,
    helperText,
    disabled,
    maximum,
    minimum,
  } = question;

  if (!label) return null;
  return (
    <NumberField
      defaultValue={value}
      name={fieldName}
      helperText={helperText}
      id={fieldName}
      error={error}
      disabled={disabled}
      maximum={maximum}
      minimum={minimum}
      onChange={(e) => { onChange(e.target.value); }}
      label={label}
    />
  );
}

function Headers({ questions }) {
  return (
    <thead>
      {Object.values(questions).map(({ question }) => <th>{question}</th>)}
    </thead>
  );
}
Headers.propTypes = {
  questions: PropTypes.instanceOf(Object).isRequired,
};

function Item({ item, questions, removeFunction }) {
  return (
    <tr className="object-array-item">
      {Object.values(questions).map(({ fld_nm: key }) => <td>{item[key]}</td>)}
      <td>
        <Button variant="icon" onClick={removeFunction}>
          <CloseIcon />
        </Button>
      </td>
    </tr>
  );
}
Item.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
  questions: PropTypes.instanceOf(Object).isRequired,
  removeFunction: PropTypes.func.isRequired,
};

function ObjectArray({ question, onChange }) {
  const {
    question: label,
    id,
    value = [],
    subfields,
  } = question;

  const [questions, setQuestions] = useState(formatQuestions(subfields));
  const responseSetter = (fieldName, newValue) => {
    const newQuestions = { ...questions };
    newQuestions[fieldName].value = newValue;
    setQuestions(newQuestions);
  };

  const add = () => {
    const entry = {};
    Object.values(questions).forEach((subquestion) => {
      entry[subquestion.fld_nm] = subquestion.value;
      responseSetter(subquestion.fld_nm, '');
    });
    onChange([...value, entry]);
  };
  const remove = (index) => {
    const newValue = value.filter((item, itemIndex) => index !== itemIndex);
    onChange(newValue);
  };

  return (
    <div className="object-array-wrapper">
      <Label text={label} htmlFor={id} />
      <table className="object-array-items">
        <Headers questions={questions} />
        <tbody>
          { value && value.map((item, index) => (
            <Item
              item={item}
              questions={questions}
              removeFunction={() => { remove(index); }}
            />
          ))}
          <tr className="object-array-fields">
            {Object.values(questions).map((field) => (
              <td>
                <Question
                  key={field.fld_nm}
                  question={field}
                  responseSetter={responseSetter}
                />
              </td>
            ))}
            <td>
              <Button variant="icon" onClick={add} disabled={!value}>
                <AddIcon />
              </Button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export function Question({
  question,
  responseSetter,
  parent = {},
}) {
  const onChange = (value) => {
    const { fld_nm: fieldName, typecast } = question;
    responseSetter(fieldName, value, typecast);
  };

  if (question.display_on && parent.value !== question.display_on) return null;

  /* eslint react/jsx-props-no-spreading: off */
  /*
    Eslint bans props spreading:
      "This enhances readability of code by being more
       explicit about what props are received by the
       component. It is also good for maintainability
       by avoiding passing unintentional extra props
       and allowing react to emit warnings when invalid
       HTML props are passed to HTML elements."
    These are good reasons to ban it, but I don't want to
    write the same thing over and over, as all the question
    variants take the same props. I think by using the
    questionProps constant below I make it explicit which
    props are being passed. Additionally, none of the Question
    components are HTML tags.
  */
  let element = null;
  const questionProps = { question, onChange, parent };
  const { subfields } = question;

  if (question.instructions) return <div className="instructions">{question.question}</div>;
  if (question.multiple) {
    element = <QuestionCheckboxes {...questionProps} />;
  } else if (question.choices) {
    element = <QuestionDropdown {...questionProps} />;
  } else if (question.input_type === 'file_loader') {
    element = <QuestionFile {...questionProps} />;
  } else if (question.input_type === 'date') {
    element = <QuestionDate {...questionProps} />;
  } else if (question.input_type === 'numeric') {
    element = <QuestionNumeric {...questionProps} />;
  } else if (question.input_type === 'object-list') {
    element = <ObjectArray {...questionProps} />;
  } else {
    element = <QuestionText {...questionProps} />;
  }

  let subelements = null;
  if (subfields && (question.input_type !== 'object-list')) {
    subelements = subfields.map((subfield) => (
      <Question
        key={subfield.fld_nm}
        question={subfield}
        responseSetter={responseSetter}
        parent={question}
      />
    ));
  }

  if (subelements) {
    return (
      <div className="questionAndSubquestions">
        {element}
        {subelements}
      </div>
    );
  }
  return element;
}

const questionPropTypes = {
  question: PropTypes.shape({
    fld_nm: PropTypes.string,
    question: PropTypes.string,
    inputType: PropTypes.string,
    choices: PropTypes.oneOfType([
      PropTypes.instanceOf(Object),
      PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        }),
      ),
    ]),
    typecast: PropTypes.string,
    full_width: PropTypes.bool,
    disabled: PropTypes.bool,
  }).isRequired,
  parent: PropTypes.instanceOf(Object),
};
const questionVariantPropTypes = {
  ...questionPropTypes,
  onChange: PropTypes.func.isRequired,
};

Question.propTypes = { ...questionPropTypes, responseSetter: PropTypes.func.isRequired };
QuestionCheckboxes.propTypes = questionVariantPropTypes;
QuestionDropdown.propTypes = questionVariantPropTypes;
QuestionFile.propTypes = questionVariantPropTypes;
QuestionDate.propTypes = questionVariantPropTypes;
QuestionText.propTypes = questionVariantPropTypes;
QuestionNumeric.propTypes = questionVariantPropTypes;
ObjectArray.propTypes = questionVariantPropTypes;

function passAnswersToChildren(answers, question) {
  if (!question.subfields) return;
  question.subfields.forEach((subfield) => {
    const { fld_nm } = subfield;
    const answer = answers[fld_nm]
      ? answers[fld_nm].value
      : undefined;
    subfield.value = answer;
    passAnswersToChildren(answers, subfield);
  });
}

function propogateAnswersDownwards(questions) {
  const keys = Object.keys(questions);
  keys.forEach((key) => {
    const question = questions[key];
    passAnswersToChildren(questions, question);
  });
}

export default function Questions({
  onSubmit = undefined,
  submitButtonText = 'Submit',
  submitButtonColor = 'primary',
  submitDisabled = false,
  questions = [],
  responseSetter = () => {},
  horizontal = false,
  noValidate = false,
  reset = undefined,
  formID = undefined,
}) {
  if (!questions) return null;
  propogateAnswersDownwards(questions);
  const classNames = ['questions'];
  if (horizontal) classNames.push('horizontal');
  return (
    <form
      id={formID}
      noValidate={noValidate}
      className={classNames.join(' ')}
      onSubmit={(event) => {
        event.preventDefault();
        if (onSubmit) onSubmit();
      }}
    >
      {Object.keys(questions).map((fieldName) => (
        <Question key={fieldName} question={questions[fieldName]} responseSetter={responseSetter} />
      ))}
      {onSubmit && (
        <Button
          type="submit"
          color={submitButtonColor}
          disabled={submitDisabled}
        >
          {submitButtonText}
        </Button>
      )}
      {reset
        ? (
          <Button
            variant={reset.var === 'sfa-variant' ? 'text' : 'hollow'}
            onClick={reset.fn}
            color={reset.var === 'sfa-variant' ? 'secondary' : 'primary'}
          >
            {reset.text}
          </Button>
        )
        : null}
    </form>
  );
}

Questions.propTypes = {
  questions: PropTypes.instanceOf(Object),
  responseSetter: PropTypes.func,
  horizontal: PropTypes.bool,
  noValidate: PropTypes.bool,
  onSubmit: PropTypes.func,
  submitButtonText: PropTypes.string,
  submitButtonColor: PropTypes.string,
  submitDisabled: PropTypes.bool,
  reset: PropTypes.instanceOf(Object),
  formID: PropTypes.string,
};
