import React, { useContext, useEffect, useState } from 'react';
import propTypes from 'prop-types';

import NONE from '../../../../constants';
import Dropdown from '../../../../core/Input/Dropdown';
import Button from '../../../../core/Input/Button';
import MultipleSelect from '../../../../core/Input/MultipleSelect';
import { doManuAction } from '../../../../utils/networkUtils';
import UserContext from '../../../../UserContext';

const ASSIGN_REFEREE = 'assign-referee';
const REMOVE_REFEREE = 'remove-referee';

function doAction(manu, action) {
  if (!action) return '';
  return doManuAction(manu, action);
}

function transformAllReferees(referees) {
  return Object.keys(referees).map((key) => {
    const { _id: id, name } = referees[key];
    return { id, name };
  });
}

export function transformManuReferees(referees, manuRefs) {
  const refs = Object.keys(referees).filter((key) => manuRefs.includes(key));
  return refs.map((key) => ({
    id: key,
    name: referees[key].name,
  }));
}

function ActionOptions({
  action = NONE,
  value = undefined,
  setValue,
  manuscript,
  allReferees,
}) {
  switch (action) {
    case ASSIGN_REFEREE:
      return (
        <MultipleSelect
          options={transformAllReferees(allReferees)}
          id="assignReferee"
          label="Select a referee to assign."
          value={value}
          onChange={setValue}
          singleSelect
        />
      );
    case REMOVE_REFEREE:
      return (
        <MultipleSelect
          options={transformManuReferees(allReferees, manuscript.referees)}
          id="removeReferee"
          label="Select a referee to remove."
          value={value}
          onChange={setValue}
          singleSelect
        />
      );
    default: return null;
  }
}
ActionOptions.propTypes = {
  action: propTypes.string,
  value: propTypes.string,
  setValue: propTypes.func.isRequired,
  manuscript: propTypes.instanceOf(Object).isRequired,
  allReferees: propTypes.instanceOf(Object).isRequired,
};

function getActionOptions(column, actions) {
  return [
    { id: NONE, name: 'None' },
    ...Object.keys(column).map((id) => ({ id, name: actions[id] })),
  ].filter(({ id }) => id !== 'editor-move');
}

export default function ManuscriptActions({
  manuscript,
  column,
  refreshManuscripts,
  actions,
  allReferees,
  closeWindow,
}) {
  const { _id: manuscriptId } = manuscript;
  const [actionToDo, setActionToDo] = useState('');
  const [action, setAction] = useState(NONE);
  const [actionValue, setActionValue] = useState();
  const [error, setError] = useState();

  function useAction(manu) {
    const [state, setState] = useState('');
    const { userID } = useContext(UserContext);

    useEffect(() => {
      if (actionToDo) {
        const actionDict = {
          action: actionToDo,
          Editor: userID,
        };
        if ([ASSIGN_REFEREE, REMOVE_REFEREE].includes(actionToDo)) {
          [actionDict.referee] = actionValue;
        }
        doAction(manu, actionDict)
          .then((data) => {
            setState(data.newState);
            setActionToDo('');
            refreshManuscripts();
            closeWindow(); // For now just close window
            // Could also change column to newState
            // but that's editing a prop.
          })
          .catch(() => setError(true));
      }
    }, [actionToDo]);

    return state;
  }

  const actionNeedsValue = [
    ASSIGN_REFEREE,
    REMOVE_REFEREE,
  ].includes(action);
  useAction(manuscriptId);
  return (
    <div className="manuscript-actions-bar">
      <Dropdown
        id="manuscript-action-select"
        label="Select action"
        options={getActionOptions(column, actions)}
        onChange={({ target }) => { setActionValue(); setAction(target.value); }}
        defaultValue={NONE}
      />
      <ActionOptions
        action={action}
        value={actionValue}
        setValue={setActionValue}
        manuscript={manuscript}
        allReferees={allReferees}
      />
      {action !== NONE && (
        <Button
          disabled={actionNeedsValue && !actionValue}
          type="button"
          onClick={() => { setActionToDo(action); }}
        >
          Submit
        </Button>
      )}
      {error && <p>Server error. Oops.</p>}
    </div>
  );
}
ManuscriptActions.propTypes = {
  manuscript: propTypes.instanceOf(Object).isRequired,
  column: propTypes.instanceOf(Object).isRequired,
  refreshManuscripts: propTypes.func.isRequired,
  actions: propTypes.instanceOf(Object).isRequired,
  allReferees: propTypes.instanceOf(Object).isRequired,
  closeWindow: propTypes.func.isRequired,
};
