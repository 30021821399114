import React, { isValidElement } from 'react';
import propTypes from 'prop-types';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import { COLLECTIONS_PATH } from '../../../constants';
import { cropText, isExternalLink } from '../../Utils/stringUtils';

import DynamicTooltip from '../DynamicTooltip';
import Tooltip from '../Tooltip';
import MarkdownParser from '../../Transform/MarkdownParser';
import Checkbox from '../../Input/Checkbox';

import './Table.css';
import columnProps from './columnProps';

function getCellStyle(column) {
  const style = column.width
    ? {
      minWidth: `${column.width}ch`,
      maxWidth: `${column.width}ch`,
    } : undefined;
  return style;
}

function SortIcon({ sort }) {
  return sort.ascending
    ? <ArrowUpwardIcon key="up-icon" />
    : <ArrowDownwardIcon key="down-icon" />;
}
SortIcon.propTypes = {
  sort: propTypes.shape({
    ascending: propTypes.bool,
  }).isRequired,
};

function isFloat(number) {
  if (typeof number !== 'number') return false;
  return (!Number.isInteger(number) && `${number}`.length > 4);
}

function isInt(number) {
  if (typeof number !== 'number') return false;
  return (Number.isInteger(number)
    && `${number}`.length > 4);
}

function formatFloat(value, decimalPlaces = 2) {
  const number = Number(value);
  if (Number.isNaN(number)) return value;
  return number.toLocaleString('en-US', {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  });
}

function isNullOrUndefined(value) {
  return [null, undefined].includes(value);
}

export function formatCellValue(value, column) {
  const { type, decimalPlaces } = column;
  if (isNullOrUndefined(value)) return null;

  switch (type) {
    case 'int': return value.toLocaleString('en-US');
    case 'float': return formatFloat(value, decimalPlaces);
    default:
  }
  if (isFloat(value)) return formatFloat(value, decimalPlaces);
  if (isInt(value)) return value.toLocaleString('en-US');
  if (typeof value === 'string') {
    return value.replace('$ORIGIN', COLLECTIONS_PATH);
  }
  return value;
}

function cellTypeToClass(type, value) {
  if (typeof value === 'number') return 'table-cell-number';
  switch (type) {
    case 'int': return 'table-cell-number';
    case 'float': return 'table-cell-number';
    default: return 'table-cell';
  }
}

function CellLink({ link = '', linkActivate = '', content }) {
  if (!link) return null;

  const backendLink = !link.includes('http');
  const baseURL = backendLink
    ? process.env.REACT_APP_BACKEND_BASE_URL
    : '';
  const path = String(link).replace('$VAL', content);
  const cellLink = `${baseURL}${path}`;
  if (linkActivate === 'hover') {
    return (
      <DynamicTooltip link={cellLink}>
        <div>{content}</div>
      </DynamicTooltip>
    );
  }
  return <a href={cellLink}>{content}</a>;
}
CellLink.propTypes = {
  link: propTypes.string,
  linkActivate: propTypes.string,
  content: propTypes.string.isRequired,
};

const CHECKBOX = 'checkbox';

export function mapContents(contents, map) {
  if (map[contents]) return map[contents];
  if (Array.isArray(contents)) return contents.map((element) => mapContents(element, map));
  return contents;
}
function joinIfArray(element) {
  if (Array.isArray(element)) return element.join(', ');
  return String(element);
}

function getClassNames(row, column) {
  const { type, id, sticky } = column;
  const classNames = [
    cellTypeToClass(type, row[id]),
    sticky ? 'stickyLeft' : '',
  ];
  return classNames;
}
function getClassName(row, column) { return getClassNames(row, column).join(' '); }

const TYPES = {
  LINK: 'link',
  CHECKBOX: 'checkbox',
  MARKDOWN: 'markdown',
  PLAINTEXT: 'plaintext',
};
function determineCellType(column) {
  const { link, type, markdown } = column;
  if (link) return TYPES.LINK;
  if (type === CHECKBOX) return TYPES.CHECKBOX;
  if (markdown) return TYPES.MARKDOWN;
  return TYPES.PLAINTEXT;
}

function extractCellContent(row, column) {
  const { map, id } = column;
  const rawContent = map
    ? joinIfArray(mapContents(row[id], map))
    : row[id];
  const content = formatCellValue(rawContent, column);
  return content;
}

function getCellID(row, column) {
  const { id } = column;
  return `${JSON.stringify(row)}-${id}`;
}

function isStringOrNumber(content) {
  return ['string', 'number'].includes(typeof content);
}

function PlainText({ content, column }) {
  const { maxLen } = column;
  if (!maxLen) return content;
  if (maxLen > content.length) return content;
  return (
    <>
      {cropText(content, maxLen)}
      <Tooltip text={content} />
    </>
  );
}
PlainText.propTypes = {
  content: propTypes.string.isRequired,
  column: columnProps.isRequired,
};

function CellContent({ row, column }) {
  const {
    link,
    linkActivate,
    id,
    onChange,
    name,
    type,
  } = column;

  const content = extractCellContent(row, column);

  if (!isValidElement(content) && !isStringOrNumber(content) && type !== CHECKBOX) return null;
  switch (determineCellType(column)) {
    case TYPES.LINK: return <CellLink link={link} content={content} linkActivate={linkActivate} />;
    case TYPES.CHECKBOX:
      return (
        <Checkbox
          id={getCellID(row, column)}
          value={row[id]}
          onChange={() => { onChange(row); }}
          label={name}
        />
      );
    case TYPES.MARKDOWN: return <MarkdownParser md={content} openNewTab={isExternalLink} />;
    default: return <PlainText content={content} column={column} />;
  }
}
CellContent.propTypes = {
  row: propTypes.instanceOf(Object).isRequired,
  column: columnProps.isRequired,
};

export default function TableCell({ row, column }) {
  if (column.hidden) return null;
  return (
    <td className={getClassName(row, column)} style={getCellStyle(column)}>
      <CellContent row={row} column={column} />
    </td>
  );
}
TableCell.propTypes = {
  row: propTypes.instanceOf(Object).isRequired,
  column: columnProps.isRequired,
};
