import React, { useState } from 'react';
import propTypes from 'prop-types';

import VisibilityIcon from '@mui/icons-material/Visibility';

import Dialog from '../../../../core/Display/Dialog';
import MultipleSelect from '../../../../core/Input/MultipleSelect';
import Button from '../../../../core/Input/Button';
import ButtonGroup from '../../../../core/Input/ButtonGroup';

import { MANUSCRIPT_PROPS } from './dashboardProps';
import { getReviewerOptions } from './FilterPanel';
import STATUSES from './statuses';
import RESPONSES from './responses';
import './Dashboard.css';
// import ResponseIcon from './ResponseIcon';
import ManuscriptContent from './ManuscriptContent';
import { LOREM } from './loremIpsum';
import ManuscriptActions, { transformManuReferees } from './ManuscriptActions';
import stringifyAuthorNames from './stringifyAuthorNames';

function renderName(name) { return (name ? name.replaceAll('-', ' ') : ''); }

function getFeedback(manuscript) {
  const { referees } = manuscript;
  const refereesWithFeedback = referees
    .filter(({ response }) => response === RESPONSES.WITH_REVISIONS);
  if (!refereesWithFeedback.length) return undefined;
  return refereesWithFeedback.map(({ name }) => ({
    refereeName: name,
    overallFeedback: LOREM,
    sectionFeedback: [],
  }));
}

function DialogActionButtons({ cancelAction, confirmAction }) {
  return (
    <ButtonGroup>
      <Button onClick={cancelAction} variant="hollow" color="secondary">
        Cancel
      </Button>
      <Button color="secondary" onClick={confirmAction}>
        Confirm
      </Button>
    </ButtonGroup>
  );
}
DialogActionButtons.propTypes = {
  cancelAction: propTypes.func.isRequired,
  confirmAction: propTypes.func.isRequired,
};

function ConfirmAcceptDialog({
  manuscript,
  cancelAction,
  confirmAction,
}) {
  const { title } = manuscript;
  const [reviewers, setReviewers] = useState([]);
  return (
    <Dialog title="Confirm rejection">
      <div>
        {'Please select 3 reviewers for '}
        <span className="color-secondary">{title}</span>
        .
        <div className="input-container">
          <MultipleSelect
            value={reviewers}
            onChange={setReviewers}
            label="Reviewers"
            options={getReviewerOptions()}
          />
        </div>
      </div>
      <DialogActionButtons
        cancelAction={cancelAction}
        confirmAction={() => { confirmAction(reviewers); }}
      />
    </Dialog>
  );
}
ConfirmAcceptDialog.propTypes = {
  manuscript: MANUSCRIPT_PROPS.isRequired,
  cancelAction: propTypes.func.isRequired,
  confirmAction: propTypes.func.isRequired,
};

function ConfirmRejectDialog({
  manuscript,
  cancelAction,
  confirmAction,
}) {
  const { title } = manuscript;
  return (
    <Dialog title="Confirm rejection">
      <div>
        {'Are you sure you want to reject '}
        <span className="color-secondary">{title}</span>
        ?
      </div>
      <DialogActionButtons cancelAction={cancelAction} confirmAction={confirmAction} />
    </Dialog>
  );
}
ConfirmRejectDialog.propTypes = {
  manuscript: MANUSCRIPT_PROPS.isRequired,
  cancelAction: propTypes.func.isRequired,
  confirmAction: propTypes.func.isRequired,
};

const ACTIONS = {
  NONE: 0,
  REJECT: 1,
  ACCEPT: 2,
  VIEW_FULL: 3,
};

export default function ManuscriptDetails({
  manuscript,
  setViewedManuscript,
  manuscripts,
  setManuscripts,
  column = {},
  refreshManuscripts,
  actions,
  allReferees,
}) {
  const [action, setAction] = useState(ACTIONS.NONE);
  if (!manuscript) return <div className="manuscript-wrapper empty" />;

  const closeWindow = () => { setViewedManuscript(undefined); };

  const viewFull = () => { setAction(ACTIONS.VIEW_FULL); };
  /* eslint no-param-reassign: off */ // :)
  const confirmReject = () => {
    manuscript.state = STATUSES.REJECTED;
    manuscript.timeUpdated = new Date();
    setManuscripts([...manuscripts]);
    setAction(ACTIONS.NONE);
    closeWindow();
  };
  const confirmAccept = (reviewers) => {
    manuscript.state = STATUSES.UNDER_REFEREE;
    manuscript.reviewers = reviewers.map((name) => ({
      name,
      response: RESPONSES.PENDING,
    }));
    manuscript.timeUpdated = new Date();
    setManuscripts([...manuscripts]);
    setAction(ACTIONS.NONE);
    closeWindow();
  };
  const cancelAction = () => { setAction(ACTIONS.NONE); };

  manuscript.timeUpdated = new Date(manuscript.timeUpdated);

  const {
    title,
    authors,
    state,
    timeUpdated,
    referees,
  } = manuscript;
  const authorNames = stringifyAuthorNames(authors);
  return (
    <>
      {action === ACTIONS.REJECT && (
        <ConfirmRejectDialog
          manuscript={manuscript}
          cancelAction={cancelAction}
          confirmAction={confirmReject}
        />
      )}
      {action === ACTIONS.ACCEPT && (
        <ConfirmAcceptDialog
          manuscript={manuscript}
          cancelAction={cancelAction}
          confirmAction={confirmAccept}
        />
      )}
      {action === ACTIONS.VIEW_FULL && (
        <ManuscriptContent
          feedback={getFeedback(manuscript)}
          closeFunction={cancelAction}
          manuscript={manuscript}
        />
      )}
      <Dialog
        title={title}
        subtitle={authorNames}
        closeFn={closeWindow}
        className="manuscript-wrapper"
      >
        <section className="abstract">
          <h3>Abstract</h3>
          <p>{manuscript.abstract}</p>
          <Button
            disabled
            onClick={viewFull}
            startIcon={<VisibilityIcon />}
          >
            View Full Manuscript
          </Button>
        </section>
        <section className="status">
          <h3>Status</h3>
          <div>{renderName(state)}</div>
          <span>
            {'Last updated: '}
            <time>
              {timeUpdated && timeUpdated.toLocaleDateString('en-US')}
            </time>
          </span>
        </section>
        {Array.isArray(referees) && referees.length > 0 && (
          <section>
            <h3>Referees</h3>
            {/* {referees.map(({ name, response }) => (
              <div className="referee">
                {name}
                <div>
                  {response}
                  <ResponseIcon response={response} />
                </div>
              </div>
            ))} */}
            {transformManuReferees(allReferees, manuscript.referees).map(({ name }) => (
              <div className="referee">
                {name}
              </div>
            ))}
          </section>
        )}
        <section className="actions">
          <h3>Actions</h3>
          <ManuscriptActions
            manuscript={manuscript}
            column={column}
            refreshManuscripts={refreshManuscripts}
            actions={actions}
            allReferees={allReferees}
            closeWindow={closeWindow}
          />
        </section>
      </Dialog>
    </>
  );
}
ManuscriptDetails.propTypes = {
  manuscript: MANUSCRIPT_PROPS.isRequired,
  setViewedManuscript: propTypes.func.isRequired,
  manuscripts: propTypes.arrayOf(MANUSCRIPT_PROPS).isRequired,
  setManuscripts: propTypes.func.isRequired,
  column: propTypes.shape({
    text: propTypes.string,
    options: propTypes.instanceOf(Object),
  }),
  refreshManuscripts: propTypes.func.isRequired,
  actions: propTypes.instanceOf(Object).isRequired,
  allReferees: propTypes.instanceOf(Object).isRequired,
};
