import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Outlet,
  Link,
} from 'react-router-dom';

import Page404 from '../Page404';
import Button from '../core/Input/Button';

import LoginForm from '../components/LoginForm/LoginForm';
import SignOut from '../components/SignOut/SignOut';
import Header from '../components/CosmosAndTaxis/Header';
import Footer from '../components/Footer/Footer';
import Profile from '../components/Profile/Profile';
import ResetForm from '../components/Reset/ResetForm';
import ResetPassword from '../components/Reset/ResetPassword';
import AuthRedirect from '../components/Common/AuthRedirect';
import Dashboard from '../components/SFA/Journal/Dashboard';

import {
  NULL_PATH,
  HOME_PATH,
  RESET_PATH,
  RESET_PW_PATH,
  PROFILE_PATH,
  LOGIN_PATH,
  LOGOUT_PATH,
  APP_STYLE,
  getPath,
} from '../constants';

function Home() {
  return (
    <AuthRedirect key={Date.now()}>
      <Dashboard />
    </AuthRedirect>
  );
}

function SiteWrapper() {
  return (
    <div>
      <Header />
      <div className="sizing">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}

function FailedAuthorization() {
  return (
    <div
      style={{
        maxWidth: '50rem',
        margin: 'auto',
        padding: '5rem 0',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <h1>
        Failed Authorization
      </h1>
      <p>
        {`It seems like you have not been authorized to view the Dashboard.
          If you think this is a mistake, try logging out and in again.
          If that doesn't work, either you have not been granted access or something
          has gone wrong on our end.`}
      </p>
      <Link to={LOGOUT_PATH}>
        <Button>
          Sign out
        </Button>
      </Link>
    </div>
  );
}

function DefaultRoutes() {
  const favicons = {
    default: '/favicon.ico',
    SFA: '/SFAfavicon.png',
  };

  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }

    switch (APP_STYLE) {
      case 'sfa-variant':
        link.href = favicons.SFA;
        break;
      default:
        link.href = favicons.default;
    }
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<SiteWrapper />}>
          <Route path={NULL_PATH} exact element={<Home />} />
          <Route path={HOME_PATH} exact element={<Home />} />
          <Route path={getPath('SFA.JOURNALHOME')} exact element={<FailedAuthorization />} />
          <Route path={RESET_PATH} exact element={<ResetForm />} />
          <Route path={RESET_PW_PATH} element={<ResetPassword />} />
          <Route
            path={PROFILE_PATH}
            exact
            element={(
              <AuthRedirect key={Date.now()}>
                <Profile />
              </AuthRedirect>
            )}
          />
          <Route path={LOGIN_PATH} exact element={<LoginForm />} />
          <Route path={LOGOUT_PATH} exact element={<SignOut />} />
          <Route path="" element={<Home />} />
          <Route path="*" element={<Page404 />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default DefaultRoutes;
