import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';

import MultipleSelect from '../../../../core/Input/MultipleSelect';

import { FILTERS_PROPS } from './dashboardProps';
import STATUSES from './statuses';
import MANUSCRIPTS from './manuscripts';
import './Dashboard.css';

function renderName(name) { return name.replaceAll('-', ' '); }

function getAuthorOptions() {
  const allAuthors = [];
  MANUSCRIPTS.forEach(({ authors }) => {
    authors.forEach((author) => {
      if (allAuthors.includes(author)) return;
      allAuthors.push(author);
    });
  });
  return allAuthors.map((author) => ({ id: author, name: author }));
}
export function getReviewerOptions() {
  const allReviewers = [];
  MANUSCRIPTS.forEach(({ reviewers }) => {
    reviewers.forEach(({ name: reviewer }) => {
      if (allReviewers.includes(reviewer)) return;
      allReviewers.push(reviewer);
    });
  });
  return allReviewers.map((reviewer) => ({ id: reviewer, name: reviewer }));
}

export default function FilterPanel({ filters, setFilters, filterPanelOpen }) {
  const [newFilters, setNewFilters] = useState(filters);
  useEffect(() => { setNewFilters(filters); }, [filterPanelOpen]);

  const onValueChange = (field, value) => {
    setNewFilters({
      ...newFilters,
      [field]: value,
    });
  };
  const onStatusesChange = (value) => { onValueChange('statuses', value); };
  const onAuthorsChange = (value) => { onValueChange('authors', value); };
  const onReviewersChange = (value) => { onValueChange('reviewers', value); };
  const onSubmit = (e) => {
    e.preventDefault();
    setFilters(newFilters);
  };

  return (
    <section className={`dashboard-filter-panel ${filterPanelOpen ? 'open' : 'closed'}`}>
      <h2>Filters</h2>
      <form onSubmit={onSubmit}>
        <MultipleSelect
          id="filter-by-status"
          label="Filter by status"
          options={Object.keys(STATUSES).map((id) => ({
            id: STATUSES[id],
            name: renderName(STATUSES[id]),
          }))}
          onChange={onStatusesChange}
          value={newFilters.statuses}
          optional
        />
        <MultipleSelect
          id="filter-by-author"
          label="Filter by author"
          options={getAuthorOptions()}
          onChange={onAuthorsChange}
          value={newFilters.authors}
          optional
        />
        <MultipleSelect
          id="filter-by-reviewer"
          label="Filter by reviewer"
          options={getReviewerOptions()}
          onChange={onReviewersChange}
          value={newFilters.reviewers}
          optional
        />
        <button className="white-background" type="submit">Apply Filters</button>
      </form>
    </section>
  );
}
FilterPanel.propTypes = {
  filters: FILTERS_PROPS.isRequired,
  setFilters: propTypes.func.isRequired,
  filterPanelOpen: propTypes.bool.isRequired,
};
