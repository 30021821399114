const md = `
### Designed with the individual analyst and small business in mind

DataMixMaster helps you level the playing field with the big boys of the financial industry. You don’t need to spend millions of dollars on proprietary software to get the same results as the big Wall Street firms. DataMixMaster is affordable, powerful, and customizable... ready to power up your API game?

### The affordable data aggregation solution for individuals and business

- Choose any combination of publicly available datasets from the library
- Select your parameters
- Review the results
- Share the webpage or download the output
- Share your insight and recommendations
- Do it again!

We can also provide a custom frontend for you. Here's an example:
[SFA Frontend](https://sfa-frontend.herokuapp.com/)
`;

export default md;
