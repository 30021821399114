import React from 'react';
import propTypes from 'prop-types';

import Dialog from '../../../../core/Display/Dialog';
import MarkdownParser from '../../../../core/Transform/MarkdownParser';

import { MANUSCRIPT_PROPS } from './dashboardProps';
import { LOREM } from './loremIpsum';

const FEEDBACK_PROPS = propTypes.shape({
  reviewerName: propTypes.string,
  overallFeedback: propTypes.string,
  sectionFeedback: propTypes.arrayOf(propTypes.shape({
    startCharacter: propTypes.number,
    endCharacter: propTypes.number,
    comment: propTypes.string,
  })),
});

function OverallFeedback({ feedback }) {
  return (
    <section>
      <h3>Feedback</h3>
      {feedback.map(({ reviewerName, overallFeedback }) => (
        <section className="manuscript-overall-feedback">
          <h4>{reviewerName}</h4>
          <MarkdownParser md={overallFeedback} />
        </section>
      ))}
    </section>
  );
}
OverallFeedback.propTypes = { feedback: propTypes.arrayOf(FEEDBACK_PROPS).isRequired };

const SECTIONS = [
  'Abstract',
  'Part One',
  'Part Two',
  'Part Three',
  'Part Four',
  'Part Five',
  'Conclusion',
];
function Content() {
  return SECTIONS.map((title) => (
    <section>
      <h3>{title}</h3>
      <p>{LOREM}</p>
    </section>
  ));
}

export default function ManuscriptContent({
  manuscript = {},
  feedback = undefined,
  closeFunction = () => {},
}) {
  return (
    <Dialog
      title={manuscript.title}
      subtitle={manuscript.authors.join(', ')}
      closeFn={closeFunction}
    >
      <div style={{ overflow: 'auto', maxHeight: '60vh' }}>
        {feedback && <OverallFeedback feedback={feedback} />}
        <Content />
      </div>
    </Dialog>
  );
}
ManuscriptContent.propTypes = {
  feedback: propTypes.arrayOf(FEEDBACK_PROPS).isRequired,
  manuscript: MANUSCRIPT_PROPS.isRequired,
  closeFunction: propTypes.func.isRequired,
};
