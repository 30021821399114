import React from 'react';

import MarkdownParser from '../../core/Transform/MarkdownParser';
import ContentBox from '../../core/Display/ContentBox';

import './Solutions.css';

import Text from '../Common/Text';
import Flex from '../Common/FlexV2';
import Aligner from '../Common/AlignerV2';

import { setTitle } from '../../utils/browserUtils';
import solutionsText from '../../text/solutions_text';
import { MOBILE, HOMEPAGE_REPORT } from '../../constants';
import { ReportContextProvider } from '../Report/ReportContext';
import ReportRetriever from '../Report/RerunReport/ReportRetriever';

/* eslint jsx-a11y/media-has-caption: warn */
function Solutions() {
  setTitle('Solutions');

  const text = <MarkdownParser md={solutionsText} openNewTab />;
  return (
    <Flex>
      <Aligner width={MOBILE ? '0' : '20'} />
      <Aligner width={MOBILE ? '100' : '60'}>
        <Flex sx={{ justifyContent: 'center' }}>
          <ReportContextProvider>
            <ReportRetriever
              reportid={HOMEPAGE_REPORT}
              graphOnly
            />
          </ReportContextProvider>
        </Flex>
        <ContentBox>
          <div className="interior_margin">
            <Text variant="body1">
              {text}
            </Text>
          </div>
        </ContentBox>
      </Aligner>
      <Aligner width={MOBILE ? '0' : '20'} />
    </Flex>
  );
}

export default Solutions;
